<template>
  <div class="wrap" v-loading="loading">
    <div class="space left"></div>
    <div class="frame-wrapper">
      <Nav :onChange="handleNav" />
      <div class="content">
        <div class="banerText" v-if="allListData.length">
          <div class="homeText">
            {{ allListData.length && allListData[0].cate_main_name }}
          </div>
          <span class="iconfont icon homeText">&#xe76d;</span>
          <div class="homeText">
            {{ allListData.length && allListData[0].cate_sub_name }}
          </div>
          <span class="iconfont icon">&#xe76d;</span>
          <div class="userInfoText">
            {{ allListData.length && allListData[0].cate_3_name }}
          </div>
        </div>
        <div class="CardItem">
          <div class="content">
            <CardItem
              style="list-style: none;"
              :info="val"
              v-for="(val, id) in allListData"
              :key="id"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
import Nav from '../SkillIndex/./nav'
import { apiCourseList } from '@/api/IELTScourse'
import _ from 'lodash'
import CardItem from './cardItem.vue'

export default {
  components: {
    Nav,
    CardItem
  },
  data() {
    return {
      loading: false,
      allListData: []
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.allList()
  },
  methods: {
    allList() {
      this.loading = true
      const { cateMain, cateSub, cate_3 } = this.$route.query
      apiCourseList({
        cate_main: cateMain,
        cate_sub: cateSub,
        cate_3: cate_3,
        page: 1,
        page_size: 9999
      }).then((res) => {
        if (res.code == 200) {
          this.allListData = res.data.list
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  min-height: calc(100vh - 66px);
  .frame-wrapper {
    display: flex;
    margin-left: 160px;
    margin-right: 160px;
    .content {
      .CardItem {
        .content {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -12px;
        }
      }
      .banerText {
        margin-bottom: 16px;
        margin-top: 3px;
        display: flex;
        font-size: 14px;

        font-weight: 400;
        line-height: 17px;
        color: #1f1f40;
        .homeText {
          color: #9c9c9c;
          font-size: 14px;
          margin-left: 10px;
        }
        .icon {
          margin-left: 19px;
          margin-right: 19px;
          font-weight: 700;
        }
        .userInfoText {
          font-size: 14px;

          font-weight: 400;
          color: #1f1f40;
        }
      }
      flex: 1;
      margin-left: 46px;
      margin-top: 25px;
      .live-com {
        margin-left: 10px;
        margin-top: 27px;
        .header {
          display: flex;
          justify-content: space-between;
          .headerText {
            font-size: 18px;
            font-weight: bold;
            color: #1f1f40;
            margin-top: 9px;
            margin-bottom: 10px;
          }
          .gdText {
            font-size: 14px;
            font-weight: 400;
            color: #9c9c9c;
            margin-top: 13px;
            cursor: pointer;
          }
        }

        .live-left {
          width: 460px;
          height: 181px;
          background: #f5f6fa;
          border-radius: 10px;
          box-sizing: border-box;
          font-size: 16px;

          font-weight: bold;
          line-height: 19px;
          color: #1f1f40;
          padding: 17px 27px;
          .box {
            width: 462px;
            height: 175px;
            position: relative;
            background: url('../../../assets/live/02.png') no-repeat;
            box-sizing: border-box;
            margin-left: -13px;
            margin-top: -13px;
            img {
              margin-top: 35px;
              margin-left: 39px;
            }
            .teachImg {
              position: absolute;
              top: -63px;
              left: 54px;
              width: 83px;
            }
            .zbText {
              position: absolute;
              width: 38px;
              background: #ffffff;
              border-radius: 10px;
              font-size: 10px;
              color: #f44336;
              padding: 1px;
              top: 42px;
              left: 176px;
              div {
                width: 5px;
                height: 5px;
                background-color: #f44336;
                border-radius: 50%;
                display: inline-block;
                margin-bottom: 2px;
                margin-left: 3px;
              }
            }
            .zbText2 {
              position: absolute;
              width: 38px;
              background: #ffffff;
              border-radius: 10px;
              font-size: 10px;
              text-align: center;
              color: #f44336;
              padding: 1px;
              top: 42px;
              left: 176px;
            }
            .titleText {
              position: absolute;
              left: 237px;
              top: 31px;
              width: 138px;
              font-size: 14px;
              font-weight: bold;
              color: #1f1f40;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .labe {
              position: absolute;
              width: 138px;
              height: 29px;
              border-bottom: 1px solid #ebebf2;
              bottom: 64px;
              left: 234px;
            }
            .el-icon-arrow-right {
              position: absolute;
              right: 89px;
              bottom: 43px;
              color: #1f1f40;
            }
          }
        }
        .rightBox {
          box-sizing: border-box;
          width: 460px;
          height: 181px;
          background: #f5f6fa;
          border-radius: 10px;
          padding-top: 17px;
          padding-left: 29px;
          padding-right: 24px;
          .title {
            font-size: 16px;
            font-weight: bold;
            color: #1f1f40;
          }
          .textHeader {
            display: flex;
            margin-top: 17px;
            position: relative;
            .icon {
              margin-top: 3px;
            }
            div {
              margin-left: 7px;
              font-size: 14px;
              font-weight: 400;
              color: #1f1f40;
            }
            .icon2 {
              position: absolute;
              right: 0;
              cursor: pointer;
            }
          }
        }
      }
      .bank {
        padding: 10px;
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #1f1f40;
          margin-top: 14px;
        }
        .textHeader {
          display: flex;
          margin-top: 17px;
          position: relative;
          .icon {
            margin-top: 3px;
          }
          span {
            margin-left: 7px;
            font-size: 14px;
            font-weight: 400;
            color: #1f1f40;
          }
          div {
            margin-left: 14px;
            text-align: center;
            width: 44px;
            height: 17px;
            font-size: 12px;
            font-family: IBMPlexSans;
            color: #ad1625;
            background: #ffd2dd;
            border-radius: 10px;
          }
          .icon2 {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
